<template>
  <b-row class="justify-content-center">
    <b-col md="6" xl="6">
      <b-card>
        <div class="card-title">
          <h4 class="d-inline-block">Detail Bukti Pemrek</h4>
          <b-badge
            :variant="statusVariant(data.status)"
            class="ml-1 font-small-2 float-right"
          >
            {{ statusName(data.status) }}
          </b-badge>
        </div>
        <b-overlay :show="loading" rounded="sm">
          <b-form-group label="Nama Lengkap" label-for="name">
            <b-form-input id="name" type="text" v-model="data.name" readonly />
          </b-form-group>
          <b-form-group label="No Hp" label-for="phone">
            <b-form-input
              id="phone"
              maxlength="13"
              type="number"
              v-model="data.phone"
              readonly
            />
          </b-form-group>
          <b-form-group label="Tanggal Terdaftar" label-for="registerDate">
            <b-form-input id="date" v-model="data.proven_at" readonly />
          </b-form-group>
          <b-form-group label="Deskripsi" label-for="description">
            <b-form-textarea id="note" v-model="data.note" readonly />
          </b-form-group>
          <b-form-group label="Nomor Rekening" label-for="account_no">
            <b-form-input
              id="account_no"
              type="text"
              v-model="data.account_no"
              readonly
            />
          </b-form-group>
          <b-form-group
            label="Bukti Foto"
            label-for="image"
            class="input-image"
          >
            <div class="d-block text-center">
              <b-img
                thumbnail
                fluid
                :src="media"
                v-b-modal.modal-image
                @click="showImage(data.image)"
                style="height: 500px"
              />
            </div>
          </b-form-group>
          <b-modal id="modal-image" centered title="" ok-only size="lg">
            <div class="text-center">
              <b-img :src="media" class="img-fluid" />
            </div>
          </b-modal>
        </b-overlay>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { $themeConfig } from "@themeConfig";
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BButton,
  BFormFile,
  BOverlay,
  BImg,
  BBadge,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import ImageUploader from "vue-image-upload-resize";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BFormFile,
    flatPickr,
    ImageUploader,
    BOverlay,
    BImg,
    BBadge,
  },
  setup() {
    const { baseUrl } = $themeConfig.app;
    return {
      baseUrl,
    };
  },
  computed: {
    statusVariant() {
      const statusColor = {
        0: "light-secondary",
        1: "light-success",
        2: "light-primary",
        3: "light-warning",
      };

      return (status) => statusColor[status];
    },
    statusName() {
      const statusName = {
        0: "Pending",
        1: "Valid",
        2: "Uncompleted",
        3: "Need Follow Up",
      };

      return (status) => statusName[status];
    },
  },
  data() {
    return {
      loading: false,
      hasImage: false,
      media: null,
      datepicker: {
        dateFormat: "d-m-Y",
      },
      data: {
        name: null,
        phone: null,
        proven_at: null,
        image: null,
        note: null,
        account_no: null,
      },
      pemrekId: null,
    };
  },
  created() {
    this.pemrekId = this.$store.state.app.pemrekId;

    if (this.$store.state.app.pemrekId === null) {
      this.pemrekId = localStorage.getItem("pemrekId", this.pemrekId);
    }
    this.getData();
  },
  methods: {
    getData() {
      this.$http.get("/report-octo/" + this.pemrekId).then((res) => {
        var $data = res.data.data;
        this.data = $data;
        this.media = this.baseUrl + "image/report-octo/" + $data.image;
        console.log(this.data);
        console.log(this.media);
      });
    },
    showImage($filename) {
      this.media = this.baseUrl + "image/report-octo/" + $filename;
    },
    makeToast(variant = null) {
      this.$bvToast.toast("Toast body content", {
        title: `Variant ${variant || "default"}`,
        variant,
        solid: true,
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
